import { useEffect, useState } from "react";
import { BASE_URL, BEARER_TOKEN, QUOTES } from "src/api.config";
import { useAddresDetails, useUserInfo } from "src/hooks/useForms";
import { useGoingUp } from "src/hooks/useScrollUpAndDown";
import { EndingTypes } from "src/types";

interface FormTypeSubmitMessageProps {
  ending: EndingTypes;
  path: { question_id: string; id: string }[];
}
const FormTypeSubmitMessage = ({
  ending,
  path,
}: FormTypeSubmitMessageProps) => {
  const [transition, setTransition] = useState(false);
  const goingUp = useGoingUp((s) => s.goingUp);

  const userInfo = useUserInfo((s) => s.userInfo);
  const addressDetails = useAddresDetails((s) => s.addressDetails);
  const [submitFormResponse, setSubmitFormResponse] = useState();
  const [errorSubmitResponse, setErrorSubmitResponse] = useState();

  useEffect(() => {
    console.log("addressDetails", addressDetails);
  }, [addressDetails]);

  useEffect(() => {
    setTransition(false);
  }, [ending]);

  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 300);
  }, [ending]);

  const formData = new FormData();
  formData.append("_method", "POST");
  formData.append("email", userInfo?.email);
  formData.append("first_name", userInfo?.firstName);
  formData.append("last_name", userInfo?.lastName);
  formData.append("phone_number", userInfo?.phoneNumber);
  formData.append("address", addressDetails?.address);
  formData.append("city_town", addressDetails?.city);
  formData.append("state_region_province", addressDetails?.state);
  formData.append("zip_code", addressDetails?.zipCode);
  formData.append("main_part_no", ending.pricesParts.main_part_no!);
  formData.append(
    "main_part_price",
    ending.pricesParts.main_part_price?.toString()!
  );
  formData.append(
    "fitting_price",
    ending.pricesParts.fitting_price?.toString()!
  );
  formData.append("score", ending.pricesParts.score?.toString()!);
  formData.append("acc_part_no1", ending.pricesParts.acc_part_no1?.toString()!);
  formData.append(
    "acc_price_no1",
    ending.pricesParts.acc_price_no1?.toString()!
  );
  if (ending.pricesParts.acc_part_no2 !== null) {
    formData.append(
      "acc_part_no2",
      ending.pricesParts.acc_part_no2?.toString()!
    );
    formData.append(
      "acc_price_no2",
      ending.pricesParts.acc_price_no2?.toString()!
    );
  }
  if (ending.pricesParts.acc_part_no3 !== null) {
    formData.append("acc_part_no3", ending.pricesParts.acc_part_no3);
    formData.append(
      "acc_price_no3",
      ending.pricesParts.acc_price_no3?.toString()!
    );
  }
  if (ending.pricesParts.acc_part_no4 !== null) {
    formData.append("acc_part_no4", ending.pricesParts.acc_part_no4);
    formData.append(
      "acc_price_no4",
      ending.pricesParts.acc_price_no4?.toString()!
    );
  }
  if (ending.pricesParts.acc_part_no5 !== null) {
    formData.append("acc_part_no5", ending.pricesParts.acc_part_no5);
    formData.append(
      "acc_price_no5",
      ending.pricesParts.acc_price_no5?.toString()!
    );
  }
  if (ending.pricesParts.acc_part_no6 !== null) {
    formData.append("acc_part_no6", ending.pricesParts.acc_part_no6);
    formData.append(
      "acc_price_no6",
      ending.pricesParts.acc_price_no6?.toString()!
    );
  }
  if (ending.pricesParts.acc_part_no7 !== null) {
    formData.append("acc_part_no7", ending.pricesParts.acc_part_no7);
    formData.append(
      "acc_price_no7",
      ending.pricesParts.acc_price_no7?.toString()!
    );
  }
  if (ending.pricesParts.acc_part_no8 !== null) {
    formData.append("acc_part_no8", ending.pricesParts.acc_part_no8);
    formData.append(
      "acc_price_no8",
      ending.pricesParts.acc_price_no8?.toString()!
    );
  }
  if (ending.pricesParts.acc_part_no9 !== null) {
    formData.append(
      "acc_part_no8",
      ending.pricesParts.acc_part_no8?.toString()!
    );
    formData.append(
      "acc_price_no8",
      ending.pricesParts.acc_price_no9?.toString()!
    );
  }

  const createQuote = async () => {
    path.forEach((path: { question_id: string; id: string }) => {
      formData.append(`${path.question_id}`, `${path.id}`);
    });
    const response = await fetch(`${BASE_URL}${QUOTES}`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + BEARER_TOKEN,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        setSubmitFormResponse(data?.result);
        if (data.result.mailSendresult === " Mail sent ") {
          console.log(data.result);
          // setShowEnding(true);
        }
        if (data.result.error_id === "401") {
          setErrorSubmitResponse(data.result.error_msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    return response;
  };

  useEffect(() => {
    if (path?.length > 0) {
      createQuote();
    }
  }, [path]);

  if (ending?.type === "B") {
    return (
      <div
        className={`transition-all ease-in-out duration-500 ${
          transition
            ? "transform translate-y-0"
            : goingUp
            ? "-translate-y-full opacity-20"
            : "translate-y-full opacity-20"
        } w-full h-full items-center justify-center`}
      >
        <div className="flex flex-col justify-center relative lg:h-full w-full mb-10 space-y-6">
          <h1 className="font-sans text-6xl font-semibold text-center">
            {ending.line1}
          </h1>
          <h1 className="font-sans text-4xl font-semibold text-center">
            {ending.line2}
          </h1>
        </div>
      </div>
    );
  }

  if (
    ending?.type === "C" ||
    ending?.type === "D" ||
    ending?.type === "E" ||
    ending?.type === "F" ||
    ending?.type === "G"
  ) {
    return (
      <div
        className={`transition-all ease-in-out duration-500 ${
          transition
            ? "transform translate-y-0"
            : goingUp
            ? "-translate-y-full opacity-20"
            : "translate-y-full opacity-20"
        } w-full h-full items-center justify-center`}
      >
        <div className="flex flex-col justify-center relative lg:h-full w-full mb-10 space-y-10">
          <h1 className="font-sans text-6xl font-semibold text-center">
            {ending.line1}
          </h1>
          <div className="mt-4" />

          <h1 className="font-sans text-6xl font-semibold text-center">
            {ending.line2}
          </h1>
          <h1 className="font-sans text-6xl font-semibold text-center">
            {ending.line3}
          </h1>
          <div className="mt-4" />
          <h1 className="font-sans text-6xl font-semibold text-center ">
            {ending.line4}
          </h1>
          <h1 className="font-sans text-6xl font-semibold text-center">
            {ending.line5}
          </h1>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`transition-all ease-in-out duration-500 ${
        transition
          ? "transform translate-y-0"
          : goingUp
          ? "-translate-y-full opacity-20"
          : "translate-y-full opacity-20"
      } w-full h-full items-center justify-center`}
    >
      <div className="flex flex-col justify-center  relative  lg:h-full w-full mb-10">
        <div>
          <h1 className="font-sans text-6xl font-semibold text-center">
            Your Quote Total = {ending?.score}$
          </h1>
          <h3 className="text-lg text-gray-400 font-medium mt-4 text-center">
            Thank you for taking your time. We will be in touch with you soon!
            In the meantime if you have any questios please feel free to give us
            a call on 1800 022 66 or <br /> alternatively contact us via the
            webchat bubble feature.
          </h3>
          <div className="flex flex-col items-center justify-center">
            <h3 className="text-3xl text-gray-400 font-medium mt-4">
              Your Quote Details:{" "}
            </h3>
            <h5 className="text-gray-400 mt-2 text-xl font-medium">
              Name:{" "}
              <span className="text-white">
                {userInfo?.firstName} {userInfo?.lastName}
              </span>
            </h5>
            <h5 className="text-gray-400 mt-2 text-xl font-medium ">
              Phone Number:{" "}
              <span className="text-white"> {userInfo?.phoneNumber}</span>
            </h5>
            <h5 className=" text-gray-400 mt-2 text-xl font-medium">
              Email: <span className="text-white">{userInfo?.email}</span>
            </h5>
            <h5 className="text-gray-400 mt-2 text-xl font-medium">
              Branch: <span className="text-white">{ending?.branch}</span>
            </h5>
            <h5 className="text-gray-400 mt-2 text-xl font-medium">
              Your Vehicle:{" "}
              <span className="text-white">{ending?.vehicle} </span>
              <span className="text-white">[{ending?.vehicle_year}]</span>
            </h5>
            <h5 className="text-lg text-gray-400 font-medium mt-4">Options:</h5>
            <p>
              1x {ending?.acc_part_no1 ?? " _______ "} ={" "}
              {ending.pricesParts.acc_price_no1}$
            </p>
            <p>
              1x {ending?.acc_part_no2 ?? " _______ "} ={" "}
              {ending.pricesParts.acc_price_no2}$
            </p>
            <p>
              1x {ending?.acc_part_no3 ?? " _______ "} ={" "}
              {ending.pricesParts.acc_price_no3}$
            </p>
            <p>
              1x {ending?.acc_part_no4 ?? " _______ "} ={" "}
              {ending.pricesParts.acc_price_no4}$
            </p>
            <p>
              1x {ending?.acc_part_no5 ?? " _______ "} ={" "}
              {ending.pricesParts.acc_price_no5}$
            </p>
            <p>
              1x {ending?.acc_part_no6 ?? " _______ "} ={" "}
              {ending.pricesParts.acc_price_no6}$
            </p>
            <p>
              1x {ending?.acc_part_no7 ?? " _______ "} ={" "}
              {ending.pricesParts.acc_price_no7}$
            </p>
            <p>
              1x {ending?.acc_part_no8 ?? " _______ "} ={" "}
              {ending.pricesParts.acc_price_no8}$
            </p>
            <h5 className="text-gray-400 mt-2 text-xl font-medium">
              In-Store Fitting: + {ending?.fitting_price} $
            </h5>
            <h5 className="text-gray-400 mt-2 text-xl font-medium">
              (if selected item is canopy, fitting price is included)
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormTypeSubmitMessage;
