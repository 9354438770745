import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FiArrowRight } from "react-icons/fi";
import { cn } from "src/lib/utils";
import { FormProps } from "src/types";
import { Form } from "../ui/Form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { MdCheck } from "react-icons/md";
import { useGoingUp } from "src/hooks/useScrollUpAndDown";

const schemaForm = z.object({
  option: z.object({
    id: z.string().min(1),
    option: z.string().min(1),
  }),
});
type FormSchema = z.infer<typeof schemaForm>;
const FormTypeList: React.FC<FormProps> = ({
  description,
  formId,
  options,
  path,
  setPath,
  title,
}) => {
  const [transition, setTransition] = useState(false);
  const setIsGoingUp = useGoingUp((s) => s.setGoingUp);

  useEffect(() => {
    setTransition(false);
  }, [formId]);
  const form = useForm<FormSchema>({
    resolver: zodResolver(schemaForm),
    defaultValues: {
      option: {
        id: "",
        option: "",
      },
    },
  });
  const [animationFlicker, setAnimationFlicker] = useState(false);
  const [_optionSelected, setOptionSelected] = useState<{
    id: string;
    option_text: string;
    img_path: string | null;
  }>({
    id: "",
    option_text: "",
    img_path: "",
  });
  const goingUp = useGoingUp((s) => s.goingUp);
  const handleChangePath = (option: { id: string }) => {
    const objIndex = path.findIndex(
      (obj: { question_id: string; id: string }) => obj.question_id === formId
    );

    if (objIndex === -1) {
      setPath((prev: any) => [
        ...prev,
        {
          question_id: formId,
          id: option?.id,
        },
      ]);
    } else {
      path[objIndex].id = option.id;
    }
  };
  const handleStepForward = (option?: any) => {
    handleChangePath(option);
    setIsGoingUp(false);
  };
  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 300);
  }, [formId]);
  return (
    <div
      className={`transition-all ease-in-out duration-500 ${
        transition
          ? "transform translate-y-0"
          : goingUp
          ? "-translate-y-full opacity-20"
          : "translate-y-full opacity-20"
      } w-full h-full items-center justify-center`}
    >
      <div className="flex flex-col justify-center  relative  lg:h-full w-full mb-10">
        <Form {...form}>
          <h1 className="font-sans text-3xl lg:text-4xl font-medium flex items-center space-x-2">
            <h4 className="flex items-center space-x-2">
              <span className="text-lg ">{formId?.replace("q", "")}</span>
              <FiArrowRight className="h-5 w-5" />
            </h4>
            <span>
              {title === "Video question"
                ? "Do you require in-store fitting"
                : title}
              *
            </span>
          </h1>
          {description !== "" ? (
            <h3 className="text-lg text-gray-400 font-medium mt-4">
              {description}
            </h3>
          ) : (
            <div className="mt-4"></div>
          )}
          {/* <div className="mt-10" /> */}
          <Controller
            control={form.control}
            name="option"
            render={({ field }) => (
              <ul>
                {options?.map((option, i: number) => (
                  <li
                    className={cn(
                      `outline-none border  text-lg px-10 py-2 my-4 hover:bg-[#FBCE37]/40 transition cursor-pointer flex items-center justify-between ${
                        field.value.id === option.id ? "border-2" : ""
                      }`,
                      animationFlicker &&
                        field.value.id === option.id &&
                        "animate-flicker"
                    )}
                    key={option.id}
                    onClick={() => {
                      field.onChange(option);
                      console.log("option", field.value.option);
                      setOptionSelected(option);
                      setAnimationFlicker(true);
                      setTimeout(() => {
                        if (
                          (formId === "q21" && option.option_text === "Yes") ||
                          (formId === "q22" &&
                            option.option_text === "Instore Pickup only")
                        ) {
                          setAnimationFlicker(false);
                        } else {
                          handleStepForward(option);
                          setAnimationFlicker(false);
                        }
                      }, 700);
                    }}
                  >
                    <p className="flex items-center space-x-2">
                      <div
                        className={cn(
                          "border h-6 w-6 bg-black/80 flex items-center justify-center rounded-full border-[#FBCE37] text-[#FBCE37] text-[15px]",
                          field.value.id === option.id &&
                            "bg-[#FBCE37] text-black/80"
                        )}
                      >
                        {i === 0 && "A"}
                        {i === 1 && "B"}
                        {i === 2 && "C"}
                        {i === 3 && "D"}
                        {i === 4 && "E"}
                      </div>
                      <p>{option.option_text}</p>
                    </p>
                    {field.value.id === option.id && (
                      <div>
                        <MdCheck className="h-7 w-7" />
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            )}
          />
          {(formId === "q21" && _optionSelected.option_text == "Yes") ||
          (formId === "q22" &&
            _optionSelected.option_text === "Instore Pickup only") ? (
            <button
              onClick={() => handleStepForward(_optionSelected)}
              className="text-blueish w-24  py-2 "
              type="button"
            >
              <p className="font-medium">Submit</p>
            </button>
          ) : (
            <button className="text-blueish w-24  py-2 " type="submit">
              <p className="font-medium">Ok</p>
            </button>
          )}
        </Form>
      </div>
    </div>
  );
};

export default FormTypeList;
