import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

interface GoingUpState {
  goingUp: boolean;
}

interface GoingUpActions {
  setGoingUp: (goingUp: boolean) => void

}

type UseGoingUp = GoingUpState & GoingUpActions


const useGoingUp = create<UseGoingUp>()(
  persist(
    (set) => ({
      goingUp: false,
      setGoingUp: (goingUp) => set({ goingUp }),
    }),
    {
      name: "going_up",
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export { useGoingUp };
