import { useEffect, useState } from "react";
import { useGoingUp } from "src/hooks/useScrollUpAndDown";
import { FormProps } from "src/types";
import { Form } from "../ui/Form";
import { FiArrowRight } from "react-icons/fi";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { cn } from "src/lib/utils";
import { MdCheck } from "react-icons/md";
import { URL } from "src/api.config";
const schemaForm = z.object({
  option: z.object({
    id: z.string().min(1),
    option: z.string().min(1),
  }),
});
type FormSchema = z.infer<typeof schemaForm>;
const FormTypeSelectorWithImage: React.FC<FormProps> = ({
  description,
  formId,
  options,
  path,
  setPath,
  title,
}) => {
  const [animationFlicker, setAnimationFlicker] = useState(false);
  const [_optionSelected, setOptionSelected] = useState<{
    id: string;
    option_text: string;
    img_path: string | null;
  }>({
    id: "",
    option_text: "",
    img_path: "",
  });
  const [transition, setTransition] = useState(false);
  const setIsGoingUp = useGoingUp((s) => s.setGoingUp);

  useEffect(() => {
    setTransition(false);
  }, [formId]);
  const goingUp = useGoingUp((s) => s.goingUp);
  const form = useForm<FormSchema>({
    resolver: zodResolver(schemaForm),
    defaultValues: {
      option: {
        id: "",
        option: "",
      },
    },
  });
  const handleChangePath = (option: { id: string }) => {
    const objIndex = path.findIndex(
      (obj: { question_id: string; id: string }) => obj.question_id === formId
    );

    if (objIndex === -1) {
      setPath((prev: any) => [
        ...prev,
        {
          question_id: formId,
          id: option?.id,
        },
      ]);
    } else {
      path[objIndex].id = option.id;
    }
  };
  const handleStepForward = (option?: any) => {
    handleChangePath(option);
    setIsGoingUp(false);
  };
  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 300);
  }, [formId]);
  return (
    <div
      className={`transition-all ease-in-out duration-500 ${
        transition
          ? "transform translate-y-0"
          : goingUp
          ? "-translate-y-full opacity-20"
          : "translate-y-full opacity-20"
      } w-full h-full items-center justify-center`}
    >
      <div className="flex flex-col justify-center  relative  lg:h-full w-full mb-10">
        <Form {...form}>
          <h1 className="font-sans text-3xl lg:text-4xl font-medium flex items-center space-x-2">
            <h4 className="flex items-start lg:items-center space-x-2">
              <span className="text-sm lg:text-lg ">
                {formId?.replace("q", "")}
              </span>
              <FiArrowRight className="h-5 w-5" />
            </h4>
            <span>{title}*</span>
          </h1>
          {description !== "" ? (
            <h3 className="text-lg text-gray-400 font-medium mt-4">
              {description}
            </h3>
          ) : (
            <div className="mt-4" />
          )}
          <Controller
            control={form.control}
            name="option"
            render={({ field }) => (
              <ul className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                {options?.map((option, i: number) => (
                  <li
                    className={cn(
                      `outline-none border flex flex-col text-lg hover:bg-[#FBCE37]/40 transition  py-2 my-4 cursor-pointer rounded relative items-center justify-between w-full ${
                        field.value.id === option.id ? "border-2" : ""
                      }`,
                      animationFlicker &&
                        field.value.id === option.id &&
                        "animate-flicker"
                    )}
                    key={option.id}
                    onClick={() => {
                      field.onChange(option);

                      setOptionSelected(option);
                      setAnimationFlicker(true);
                      setTimeout(() => {
                        handleStepForward(option);
                        setAnimationFlicker(false);
                      }, 700);
                    }}
                  >
                    <img
                      src={`${URL}${option.img_path}`}
                      alt={`${option.id}`}
                    />
                    <p className="flex items-center space-x-2 my-2">
                      <div
                        className={cn(
                          "border h-6 w-6 bg-black/80 flex items-center justify-center rounded-full border-[#FBCE37] text-[#FBCE37] text-[15px]",
                          field.value.id === option.id &&
                            "bg-[#FBCE37] text-black/80"
                        )}
                      >
                        {i === 0 && "A"}
                        {i === 1 && "B"}
                        {i === 2 && "C"}
                        {i === 3 && "D"}
                        {i === 4 && "E"}
                      </div>
                      <p>{option.option_text}</p>
                    </p>
                    {field.value.id === option.id && (
                      <div className="absolute -top-[2px] -right-[2px] triangle">
                        <MdCheck className="h-6 w-6 text-black ml-7 mt-1" />
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            )}
          />
        </Form>
      </div>
    </div>
  );
};

export default FormTypeSelectorWithImage;
