import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { RiDoubleQuotesL } from "react-icons/ri";
import FormTypeSelectorWithImage from "./FormTypeSelectorWithImage";
import { useGoingUp } from "src/hooks/useScrollUpAndDown";

interface FormTypeMessageTypes {
  formId: string;
  path: any;
  setPath: Dispatch<SetStateAction<any>>;
  title: string;
  actualStep: any;
}

const FormTypeMessage: React.FC<FormTypeMessageTypes> = ({
  formId,
  path,
  setPath,
  title,
  actualStep,
}) => {
  const [transition, setTransition] = useState(false);
  const goingUp = useGoingUp((s) => s.goingUp);
  const [showOptions, setShowOptions] = useState(false);
  const handleContinue = () => {
    setShowOptions(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 300);
  }, [formId]);

  if (!showOptions)
    return (
      <div
        className={`transition-all ease-in-out duration-500 ${
          transition
            ? "transform translate-y-0 opacity-100"
            : goingUp
            ? "-translate-y-full opacity-20"
            : "translate-y-full opacity-20"
        }  w-full h-full items-center justify-center`}
      >
        <div className="flex flex-col items-center justify-center lg:h-full relative  w-full mb-10">
          <div>
            <h1 className="font-sans text-4xl font-medium flex items-start space-x-2">
              <h4 className="flex items-center space-x-2">
                <RiDoubleQuotesL className="h-5 w-5" />
              </h4>
              <span>
                {title} <br />
                <br />
                {/* {message} */}
              </span>
            </h1>
            <div className="mt-10" />
            <div className="flex items-center space-x-2 mr-auto">
              <button
                onClick={() => {
                  handleContinue();
                }}
                className="  text-white w-32  py-2 mt-4"
              >
                <p className="font-medium"> Continue</p>
              </button>
              <p className="text-white text-xs font-thin">
                press <span className="font-semibold">Enter ↵</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  else {
    return (
      <FormTypeSelectorWithImage
        description={actualStep?.questions[0]?.optional_detail ?? ""}
        title={actualStep?.questions[0]?.text}
        formId={actualStep?.questions[0]?.question_id}
        options={actualStep?.options}
        questions={actualStep?.questions}
        path={path}
        setPath={setPath}
      />
    );
  }
};

export default FormTypeMessage;
