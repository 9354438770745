let MODE = 1


export let URL = "http://192.168.1.46/"
export let BASE_URL = "http://192.168.1.46/labtest/elite-api-autoquote/v1/"



if (MODE === 2) {
    URL = "http://192.168.1.46/"
    BASE_URL = "http://192.168.1.46/labtest/elite-api-autoquote/v1/"
} else if (MODE === 1) {
    URL = "https://api.piranhaoffroad.es/"
    BASE_URL = "https://api.piranhaoffroad.es/"
}


// export const BEARER_TOKEN = "8e0fae9c4d2dd3dde3178101dc45a778"
export const BEARER_TOKEN = "3bf06b8801ef761fdcd045b208f02e5b"
export const FORM = "form"
export const QUOTES = "quotes"