import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FormProps } from "src/types";
import { z } from "zod";
import { Form } from "../ui/Form";
import { FiArrowRight } from "react-icons/fi";
import { cn } from "src/lib/utils";
import { MdCheck } from "react-icons/md";
import { useGoingUp } from "src/hooks/useScrollUpAndDown";
import { useUserInfo } from "src/hooks/useForms";

const schemaForm = z.object({
  option: z.object({
    id: z.string().min(1),
    option: z.string().min(1),
  }),
});

type FormSchema = z.infer<typeof schemaForm>;
const FormTypeMultipleSelection: React.FC<FormProps> = ({
  description,
  options,
  title,
  formId,
  path,
  setPath,
}) => {
  const [transition, setTransition] = useState(false);
  const goingUp = useGoingUp((s) => s.goingUp);
  const [animationFlicker, setAnimationFlicker] = useState(false);
  const setIsGoingUp = useGoingUp((s) => s.setGoingUp);
  const userInfo = useUserInfo((s) => s.userInfo);

  useEffect(() => {
    console.log("userInfo", userInfo);
  }, [userInfo]);

  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 300);
  }, [formId]);

  const form = useForm<FormSchema>({
    resolver: zodResolver(schemaForm),
    defaultValues: {
      option: {
        id: "",
        option: "",
      },
    },
  });

  const onSubmit = async (values: FormSchema) => {
    console.log(values);
  };
  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  const handleStepForward = (option?: any) => {
    setIsGoingUp(false);

    handleChangePath(option);
  };

  const handleChangePath = (option: { id: string }) => {
    const objIndex = path.findIndex(
      (obj: { question_id: string; id: string }) => obj.question_id === formId
    );

    if (objIndex === -1) {
      setPath((prev: any) => [
        ...prev,
        { question_id: formId, id: option?.id },
      ]);
    } else {
      path[objIndex].id = option?.id;
    }
  };

  return (
    <div
      className={`transition-all ease-in-out duration-500 ${
        transition
          ? "transform translate-y-0 opacity-100"
          : goingUp
          ? "-translate-y-full opacity-20"
          : "translate-y-full opacity-20"
      }  w-full h-full items-center justify-center`}
    >
      <div className="flex flex-col items-center justify-center h-full relative  w-full mb-10">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <h1 className="font-sans text-3xl lg:text-4xl font-medium flex items-center space-x-2">
              <h4 className="flex items-start lg:items-center space-x-2">
                <span className="text-sm lg:text-lg ">
                  {formId?.replace("q", "")}
                </span>
                <FiArrowRight className="h-5 w-5" />
              </h4>
              <span>{title}*</span>
            </h1>
            {description !== "" && (
              <h3 className="text-lg text-gray-400 font-medium mt-4">
                {description}
              </h3>
            )}
            <div className="mt-10" />
            <Controller
              name="option"
              control={form.control}
              render={({ field }) => (
                <ul>
                  {options.map((option, i) => (
                    <li
                      className={cn(
                        `outline-none text-lg px-10 py-2 my-4 cursor-pointer flex items-center justify-between hover:bg-[#FBCE37]/40 transition  ${
                          field.value.id === option.id ? "border-2 " : "border"
                        }`,
                        animationFlicker &&
                          form.getValues("option").id === option?.id &&
                          "animate-flicker"
                      )}
                      key={option.id}
                      onClick={() => {
                        field.onChange({
                          id: option.id,
                          option: option.option_text,
                        });
                        setAnimationFlicker(true);
                        setTimeout(() => {
                          handleStepForward(option);

                          setAnimationFlicker(false);
                        }, 700);
                      }}
                    >
                      <p className="flex items-center space-x-2">
                        <div
                          className={cn(
                            "border h-6 w-6 bg-black/80 flex items-center justify-center rounded-full border-[#FBCE37] text-[#FBCE37] text-[15px]",
                            field.value.id === option.id &&
                              "bg-[#FBCE37] text-black/80"
                          )}
                        >
                          {letters.split("").map((letter, index) => {
                            return <>{index === i && letter}</>;
                          })}
                        </div>
                        <p>{option.option_text}</p>
                      </p>
                      {field.value.id === option.id && (
                        <MdCheck className="h-7 w-7" />
                      )}
                    </li>
                  ))}
                </ul>
              )}
            />
            <button className=" text-blueish w-20 ml-auto py-2" type="submit">
              <p className="font-medium">Ok</p>
            </button>
          </form>
        </Form>
      </div>
    </div>
  );
};

export default FormTypeMultipleSelection;
