import { Dispatch, SetStateAction, useState } from "react";
import { cn } from "src/lib/utils";

interface OptionsToSelectProps {
  val: { value?: string; label: string };
  handleStepForward: (values: any) => void;
  setAnimationFlicker: Dispatch<SetStateAction<boolean>>;
  animationFlicker: boolean;
  optionSelected: {
    id: string;
    option: string;
  };
  setOptionSelected: Dispatch<SetStateAction<any>>;
  field: any;
  setShowModalOptions: Dispatch<SetStateAction<boolean>>;
}
const OptionsToSelect = ({
  handleStepForward,
  val,
  //   animationFlicker,

  //   setAnimationFlicker,
  field,
  optionSelected,
  setShowModalOptions,
  setOptionSelected,
}: OptionsToSelectProps) => {
  const [animationFlicker, setAnimationFlicker] = useState(false);
  return (
    <li
      onClick={() => {
        field.onChange({ id: val?.value, option: val?.label });

        setOptionSelected({
          id: val?.value,
          option: val?.label,
        });
        setAnimationFlicker(true);
        setTimeout(() => {
          handleStepForward({
            id: val?.value,
            option: val?.label,
          });
          setShowModalOptions(false);
          setAnimationFlicker(false);
        }, 700);
      }}
      className={cn(
        "outline-none text-lg px-10 py-2 my-2 cursor-pointer flex items-center justify-between hover:bg-[#FBCE37]/40 border-[#FBCE37] border transition",
        animationFlicker &&
          field.value.id === optionSelected?.id &&
          "animate-flicker"
      )}
    >
      {val.label}
    </li>
  );
};

export default OptionsToSelect;
