import { BASE_URL, BEARER_TOKEN, FORM } from "src/api.config";
import { useEffect, useState } from "react";
import { mappedString, pathMap } from "src/atom/atoms";
import { useAtom } from "jotai";
import { ActualStepTypes } from "src/types";
import FormTypeMultipleSelection from "./forms/FormTypeMultipleSelection";
import FormTypeSelect from "./forms/FormTypeSelect";
import FormTypeSelectorWithImage from "./forms/FormTypeSelectorWithImage";
import { useUserInfo } from "src/hooks/useForms";
import FormTypeIntroduction from "./forms/FormTypeIntroduction";
import FormTypeList from "./forms/FormTypeList";
import LineProgress from "./lineProgress/LineProgress";
import FormTypeMessage from "./forms/FormTypeMessage";
import FormAddressDetails from "./forms/FormAddressDetails";
import { useQuery } from "@tanstack/react-query";
import FormTypeSubmitMessage from "./forms/FormTypeSubmitMessage";
import FormTypeSelectorWithImageMultiple from "./forms/FormTypeSelectorWithImageMultiple";
import FormTypeListMultiple from "./forms/FormTypeListMultiple";

const FormTestLogic = () => {
  const [stringPath, setStringPath] = useAtom(mappedString);
  const [path, setPath] = useAtom(pathMap);
  const userInfo = useUserInfo((s) => s.userInfo);
  const [percentage, setPercentage] = useState(0);
  const [actualStep, setActualStep] = useState<ActualStepTypes>({
    options: [],
    questions: [],
    messages: [],
    ending: {
      branch: null,
      fitting_price: 0,
      main_part_no: null,
      main_part_price: 0,
      score: 0,
      vehicle: null,
      baseline_fiberglass_conopy: null,
      next_gen_amarok: null,
      vehicle_year: "",
      premium_fiberglass_conopy: null,
      type: "",
      pricesParts: {
        acc_part_no1: null,
        acc_part_no2: null,
        acc_part_no3: null,
        acc_part_no4: null,
        acc_part_no5: null,
        acc_part_no6: null,
        acc_part_no7: null,
        acc_part_no8: null,
        acc_part_no9: null,
        acc_price_no1: null,
        acc_price_no2: null,
        acc_price_no3: null,
        acc_price_no4: null,
        acc_price_no5: null,
        acc_price_no6: null,
        acc_price_no7: null,
        acc_price_no8: null,
        acc_price_no9: null,
        baseline_fiberglass_canopy: null,
        fitting_price: null,
        main_part_no: null,
        main_part_price: null,
        next_gen_amarok: null,
        powdercoat_price: null,
        premium_fiberglass_conopy: null,
        score: null,
      },
    },
    submit: {
      address: "required",
      address_line2: "optional",
      city_town: "required",
      country_code: "optional",
      state_region_province: "required",
      zip_code: "required",
      type: "",
    },
  });

  const getForm = async () => {
    const data = await fetch(`${BASE_URL}${FORM}${stringPath ?? ""}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + BEARER_TOKEN,
      },
    })
      .then((res) => res.json())

      .catch((err) => {
        console.log(err);
      });

    return data.result;
  };

  const { data: formData, isLoading } = useQuery({
    queryKey: ["form", stringPath],
    queryFn: getForm,
  });

  useEffect(() => {
    setActualStep(formData);
  }, [formData]);

  useEffect(() => {
    console.log(["61", "62", "63"].join(","));
  }, []);

  useEffect(() => {
    const stringMapped = path
      .map(
        (path: { question_id: string; id: string | [] }, i) =>
          `${i === 0 ? "?" : "&"}${path.question_id}=${
            typeof path.id === "string" ? path?.id : path.id.join(",")
          }`
      )
      .flat()
      .join(",");
    let cleanedString = stringMapped.replace(/,(&)/g, "$1");
    setStringPath(cleanedString);
  }, [path]);

  if (actualStep && !isLoading)
    return (
      <>
        {/* <LineProgress percentage={percentage} /> */}
        {/* <div className=" max-w-3xl lg:mx-auto no-scrollbar px-4 lg:px-0 py-2 min-h-[500px] lg:min-h-full max-h-[700px] lg:max-h-full lg:h-full overflow-y-auto w-full items-center justify-center relative  flex flex-col mx-4"> */}
        <div className=" max-w-3xl lg:mx-auto no-scrollbar px-4 lg:px-0 min-h-[500px] lg:min-h-full max-h-[700px]  overflow-y-auto w-full items-center justify-center relative  flex flex-col mx-4">
          {userInfo.email === "" && (
            <FormTypeIntroduction setPercentage={setPercentage} />
          )}
          {!actualStep?.messages &&
            userInfo.email !== "" &&
            actualStep?.questions &&
            actualStep?.ending !== null &&
            (actualStep?.questions[0]?.question_id === "q1" ||
              actualStep?.questions[0]?.question_id === "q9") && (
              <FormTypeMultipleSelection
                description={actualStep?.questions[0]?.optional_detail ?? ""}
                title={actualStep?.questions[0]?.text}
                options={actualStep.options}
                questions={actualStep.questions}
                formId={actualStep.questions[0]?.question_id}
                path={path}
                setPath={setPath}
              />
            )}
          {actualStep?.messages?.length! > 0 && actualStep?.ending !== null && (
            <FormTypeMessage
              formId={actualStep.messages?.[0]?.question_id!}
              path={path}
              setPath={setPath}
              title={actualStep?.messages?.[0]?.text!}
              actualStep={actualStep}
            />
          )}
          {!actualStep?.messages &&
            actualStep?.ending !== null &&
            actualStep?.questions &&
            (actualStep?.questions[0]?.question_id === "q2" ||
              actualStep?.questions[0]?.question_id === "q3") && (
              <FormTypeSelect
                description={actualStep?.questions[0]?.optional_detail ?? ""}
                title={actualStep?.questions[0]?.text}
                formId={actualStep?.questions[0]?.question_id}
                options={actualStep?.options}
                questions={actualStep?.questions}
                path={path}
                setPath={setPath}
              />
            )}
          {!actualStep?.messages &&
            actualStep?.ending !== null &&
            actualStep?.questions &&
            (actualStep?.questions[0]?.question_id === "q6" ||
              actualStep?.questions[0]?.question_id === "q7" ||
              actualStep?.questions[0]?.question_id === "q10" ||
              actualStep?.questions[0]?.question_id === "q11" ||
              actualStep?.questions[0]?.question_id === "q12" ||
              actualStep?.questions[0]?.question_id === "q13") && (
              // actualStep?.questions[0]?.question_id === "q16"
              <FormTypeSelectorWithImage
                description={actualStep?.questions[0]?.optional_detail ?? ""}
                title={actualStep?.questions[0]?.text}
                formId={actualStep?.questions[0]?.question_id}
                options={actualStep?.options}
                questions={actualStep?.questions}
                path={path}
                setPath={setPath}
              />
            )}
          {!actualStep?.messages &&
            actualStep?.ending !== null &&
            actualStep.questions &&
            actualStep?.questions[0]?.question_id === "q16" && (
              <FormTypeSelectorWithImageMultiple
                description={actualStep?.questions[0]?.optional_detail ?? ""}
                title={actualStep?.questions[0]?.text}
                formId={actualStep?.questions[0]?.question_id}
                options={actualStep?.options}
                questions={actualStep?.questions}
                path={path}
                setPath={setPath}
              />
            )}
          {!actualStep?.messages &&
            actualStep?.ending !== null &&
            actualStep?.questions &&
            actualStep?.questions[0]?.question_id === "q20" && (
              <FormTypeListMultiple
                description={actualStep?.questions[0]?.optional_detail ?? ""}
                title={actualStep?.questions[0]?.text}
                formId={actualStep?.questions[0]?.question_id}
                options={actualStep?.options}
                questions={actualStep?.questions}
                path={path}
                setPath={setPath}
              />
            )}
          {!actualStep?.messages &&
            actualStep?.ending !== null &&
            actualStep?.questions &&
            (actualStep?.questions[0]?.question_id === "q8" ||
              actualStep?.questions[0]?.question_id === "q15" ||
              actualStep?.questions[0]?.question_id === "q17" ||
              actualStep?.questions[0]?.question_id === "q18" ||
              actualStep?.questions[0]?.question_id === "q19" ||
              actualStep?.questions[0]?.question_id === "q21" ||
              actualStep?.questions[0]?.question_id === "q22") && (
              <FormTypeList
                description={actualStep?.questions[0]?.optional_detail ?? ""}
                title={actualStep?.questions[0]?.text}
                formId={actualStep?.questions[0]?.question_id}
                options={actualStep?.options}
                questions={actualStep?.questions}
                path={path}
                setPath={setPath}
              />
            )}
          {userInfo.email !== "" &&
            actualStep?.ending?.type !== "B" &&
            actualStep.ending && (
              <FormTypeSubmitMessage ending={actualStep.ending} path={path} />
            )}

          {userInfo.email !== "" &&
            actualStep.ending?.type === "B" &&
            actualStep?.submit?.type === "Address_info" && (
              <FormAddressDetails ending={actualStep.ending} path={path} />
            )}
        </div>
      </>
    );
  else {
    return null;
  }
};

export default FormTestLogic;
