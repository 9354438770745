import { useState } from "react";
import { MdCheck } from "react-icons/md";
import { URL } from "src/api.config";
import { cn } from "src/lib/utils";

interface ImageCardOptionProps {
  option: any;
  selectedOptions: any;
  toggleOption: (args: any) => void;
  options: any;
}

const ImageCardOption: React.FC<ImageCardOptionProps> = ({
  option,
  selectedOptions,
  toggleOption,
  options,
}) => {
  const [animationFlicker, setAnimationFlicker] = useState(false);
  return (
    <li
      className={cn(
        `outline-none border flex flex-col text-lg hover:bg-[#FBCE37]/40 transition  py-2 my-4 cursor-pointer rounded relative items-center justify-between w-full ${
          selectedOptions.includes(option.id) ? "border-2" : ""
        }`,
        animationFlicker &&
          selectedOptions.includes(option.id) &&
          "animate-flicker"
      )}
      key={option.id}
      onClick={() => {
        toggleOption(option.id);
        setAnimationFlicker(true);
        setTimeout(() => {
          setAnimationFlicker(false);
        }, 700);
      }}
    >
      <img src={`${URL}${option.img_path}`} alt={`${option.id}`} />
      <p className="flex items-center space-x-2 my-2">
        <div
          className={cn(
            "border h-6 w-6 bg-black/80 flex items-center justify-center rounded-full border-[#FBCE37] text-[#FBCE37] text-[15px]",
            //   field.value.find((o) => o.id === option.id) &&
            selectedOptions.includes(option.id) && "bg-[#FBCE37] text-black/80"
          )}
        >
          {options.indexOf(option) + 1}
        </div>
        <p>{option.option_text}</p>
      </p>
      {selectedOptions.includes(option.id) && (
        <div className="absolute -top-[2px] -right-[2px] triangle">
          <MdCheck className="h-6 w-6 text-black ml-7 mt-1" />
        </div>
      )}
    </li>
  );
};

export default ImageCardOption;
