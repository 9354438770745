import { useState } from "react";
import { MdCheck } from "react-icons/md";
import { cn } from "src/lib/utils";

interface ListCardOptionProps {
  option: any;
  selectedOptions: any;
  toggleOption: (args: any) => void;
  options: any;
}

const ListCardOption: React.FC<ListCardOptionProps> = ({
  option,
  options,
  selectedOptions,
  toggleOption,
}) => {
  const [animationFlicker, setAnimationFlicker] = useState(false);
  const isSelected = selectedOptions.includes(option.id);
  return (
    <li
      className={cn(
        `outline-none border  text-lg px-10 py-2 my-4 hover:bg-[#FBCE37]/40 transition cursor-pointer flex items-center justify-between ${
          isSelected ? "border-2" : ""
        }`,
        animationFlicker && isSelected && "animate-flicker"
      )}
      key={option.id}
      onClick={() => {
        toggleOption(option.id);
        setAnimationFlicker(true);
        setTimeout(() => {
          setAnimationFlicker(false);
        }, 700);
      }}
    >
      <p className="flex items-center space-x-2">
        <div
          className={cn(
            "border h-6 w-6 bg-black/80 flex items-center justify-center rounded-full border-[#FBCE37] text-[#FBCE37] text-[15px]",
            isSelected && "bg-[#FBCE37] text-black/80"
          )}
        >
          {options.indexOf(option) + 1}
        </div>
        <p>{option.option_text}</p>
      </p>
      {isSelected && (
        <div>
          <MdCheck className="h-7 w-7" />
        </div>
      )}
    </li>
  );
};

export default ListCardOption;
