import { useCallback, useEffect, useState } from "react";
import { useGoingUp } from "src/hooks/useScrollUpAndDown";
import { FormProps } from "src/types";
import { Form } from "../ui/Form";
import { FiArrowRight } from "react-icons/fi";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { cn } from "src/lib/utils";
import { MdCheck } from "react-icons/md";
import { URL } from "src/api.config";
import ImageCardOption from "./ImageCardOption";

const schemaForm = z.object({
  options: z.array(
    z.object({
      id: z.any(),
      option: z.any(),
      img_path: z.any().nullable(),
    })
  ),
});

type FormSchema = z.infer<typeof schemaForm>;

const FormTypeSelectorWithImageMultiple: React.FC<FormProps> = ({
  description,
  formId,
  options,
  path,
  setPath,
  title,
}) => {
  const [_optionSelected, setOptionSelected] = useState<any>();
  const [transition, setTransition] = useState(false);
  const setIsGoingUp = useGoingUp((s) => s.setGoingUp);
  const [selectedOptions, setSelectedOptions] = useState<any>([]);

  useEffect(() => {
    setTransition(false);
  }, [formId]);

  const goingUp = useGoingUp((s) => s.goingUp);

  const form = useForm<FormSchema>({
    resolver: zodResolver(schemaForm),
    defaultValues: {
      options: [],
    },
  });

  const handleChangePath = () => {
    const objIndex = path.findIndex(
      (obj: { question_id: string; id: string }) => obj.question_id === formId
    );

    if (objIndex === -1) {
      setPath((prev: any) => [
        ...prev,
        {
          question_id: formId,
          id: selectedOptions,
        },
      ]);
    } else {
      path[objIndex].id = selectedOptions;
    }
  };

  const handleStepForward = () => {
    handleChangePath();
    setIsGoingUp(false);
  };

  const toggleOption = (optionId: string) => {
    console.log("optionId", optionId);
    setSelectedOptions((prevSelected: any) => {
      const isSelected = prevSelected.includes(optionId);
      const updatedSelectedOptions = isSelected
        ? prevSelected.filter((id: any) => id !== optionId)
        : [...prevSelected, optionId];

      form.setValue("options", updatedSelectedOptions);

      return updatedSelectedOptions;
    });
  };

  useEffect(() => {
    console.log("selectedOptions", selectedOptions);
  }, [selectedOptions]);

  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 300);
  }, [formId]);

  const getStyle = useCallback(
    (optionId: string) => {
      if (selectedOptions.includes(optionId)) {
        return {
          //   ...styleDefault,
          fillColor: "#810FCB",
          fillOpacity: 0.5,
        };
      }
      //   return styleDefault;
    },
    [selectedOptions]
  );

  const onSubmit = (values: FormSchema) => {
    console.log("values", values);
  };

  return (
    <div
      className={`transition-all ease-in-out duration-500 ${
        transition
          ? "transform translate-y-0"
          : goingUp
          ? "-translate-y-full opacity-20"
          : "translate-y-full opacity-20"
      } w-full h-full items-center justify-center`}
    >
      <div className="flex flex-col justify-center  relative  lg:h-full w-full mb-10">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <h1 className="font-sans text-3xl lg:text-4xl font-medium flex items-center space-x-2">
              <h4 className="flex items-start lg:items-center space-x-2">
                <span className="text-sm lg:text-lg ">
                  {formId?.replace("q", "")}
                </span>
                <FiArrowRight className="h-5 w-5" />
              </h4>
              <span>{title}*</span>
            </h1>
            {description !== "" ? (
              <h3 className="text-lg text-gray-400 font-medium mt-4">
                {description}
              </h3>
            ) : (
              <div className="mt-4" />
            )}
            <p>Choose as many as you like</p>
            <Controller
              control={form.control}
              name="options"
              render={({ field }) => (
                <ul className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  {options?.map((option, i: number) => (
                    <ImageCardOption
                      key={option.id}
                      options={options}
                      selectedOptions={field.value}
                      toggleOption={toggleOption}
                      option={option}
                    />
                  ))}
                </ul>
              )}
            />
            <button
              onClick={() => handleStepForward()}
              className=" text-blueish w-20 ml-auto py-2"
              type="button"
            >
              <p className="font-medium">Ok</p>
            </button>
          </form>
        </Form>
      </div>
    </div>
  );
};

export default FormTypeSelectorWithImageMultiple;
