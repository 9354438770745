import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/Form";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useUserInfo } from "src/hooks/useForms";
import { z } from "zod";
// import { countries } from "src/countries";
import "../../styles/CountrySelector.css";
import { useGoingUp } from "src/hooks/useScrollUpAndDown";

const formSchema = z.object({
  firstName: z.string().min(1, "First name is required"),
  lastName: z.string().min(1, "Last name is required"),
  // phoneNumber: z.string().min(1, "Phone number is required"),
  phoneNumber: z.string().min(1, "Phone number is required"),
  selectedCountry: z.object({
    code: z.string(),
    name: z.string(),
    phone: z.string(),
    regex: z.string(),
  }),
  email: z
    .string()
    .min(1, "Email is required")
    .email("Invalid email address")
    .refine(
      (value) => !/['";\-]/.test(value),
      "Email contains invalid characters."
    )
    .refine(
      (value) => !/\.\./.test(value),
      "Email contains consecutive periods."
    )
    .refine(
      (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
      "Email domain is invalid."
    ),
});

const countries = [
  { code: "AU", name: "Australia", phone: "61", regex: "^\\+61\\d{9}$" },
  { code: "US", name: "United States", phone: "1", regex: "^\\+1\\d{10}$" },
  { code: "GB", name: "United Kingdom", phone: "44", regex: "^\\+44\\d{10}$" },
  // Add more countries as needed
];

type FormSchema = z.infer<typeof formSchema>;

interface FormTypeIntroductionProps {
  setPercentage: Dispatch<SetStateAction<number>>;
}
const FormTypeIntroduction = ({ setPercentage }: FormTypeIntroductionProps) => {
  const [transition, setTransition] = useState(false);
  const setUserInfo = useUserInfo((s) => s.setUserInfo);
  const setIsGoingUp = useGoingUp((s) => s.setGoingUp);
  const selectBoxRef = useRef<any>(null);
  const inputBoxRef = useRef<any>(null);
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const goingUp = useGoingUp((s) => s.goingUp);
  const [filteredCountries, setFilteredCountries] = useState(countries);
  const [searchQuery, setSearchQuery] = useState("");
  // const [selectedCountry, setSelectedCountry] = useState<any>(null);
  const defaultCountry = countries[0];

  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      selectedCountry: defaultCountry,
    },
  });

  const handleOptionClick = (country: any) => {
    // setSelectedCountry(country);
    setIsDropdownActive(false);
    form.setValue("selectedCountry", country);
    // setSearchQuery("");
    // if (inputBoxRef.current) {
    //   inputBoxRef.current.value = `+${country.phone}`;
    // }
  };
  const handleSearchChange = (e: any) => {
    setSearchQuery(e.target.value.toLowerCase());
    setFilteredCountries(
      countries.filter((country) =>
        country.name.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        selectBoxRef.current &&
        !selectBoxRef.current.contains(event.target)
      ) {
        setIsDropdownActive(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const selectedCountry = form.watch("selectedCountry");

  const handleStepForward = () => {
    // setStep(2);
    setPercentage(10);
    setIsGoingUp(false);
  };

  const onSubmit = async (values: FormSchema) => {
    setUserInfo({
      firstName: values.firstName,
      email: values.email,
      lastName: values.lastName,
      phoneNumber: `+${selectedCountry.phone} ${values.phoneNumber}`,
    });
    handleStepForward();
  };

  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 300);
  }, []);
  return (
    <div
      className={`transition-transform ease-in-out duration-500 pt-10 ${
        transition
          ? "transform translate-y-0"
          : goingUp
          ? "-translate-y-full"
          : "translate-y-full"
      }  w-full h-full items-center justify-center`}
    >
      <div className="flex flex-col items-center justify-center lg:h-full relative  w-full mb-10">
        <Form {...form}>
          <form
            className="flex flex-col w-full"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <h1 className="font-sans  text-3xl lg:text-4xl font-medium flex items-center space-x-2 mb-10">
              <span>Please tell us a bit about yourself *</span>
            </h1>

            <div className="flex flex-col mt-4 space-y-5">
              <FormField
                control={form.control}
                name="firstName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="font-normal">First name *</FormLabel>
                    <FormControl>
                      <div className="w-full relative outline-none  h-10 text-sm mt-2">
                        <input
                          type="text"
                          {...field}
                          className="w-full outline-none   h-10  text-sm"
                          placeholder="Jane"
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="mt-4" />
              <FormField
                control={form.control}
                name="lastName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Last name *</FormLabel>
                    <FormControl>
                      <div className="w-full relative outline-none h-10 text-sm mt-2">
                        <input
                          type="text"
                          {...field}
                          className="w-full outline-none   h-10  text-sm"
                          placeholder="Smith"
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />{" "}
              <div className="mt-4" />
              <Controller
                control={form.control}
                name="phoneNumber"
                render={({ field, fieldState: { error } }) => (
                  <div>
                    <FormLabel>Phone number * </FormLabel>
                    <div>
                      <div className="country-selector flex space-x-2">
                        <div
                          className={`selected-option ${
                            isDropdownActive ? "active" : ""
                          }`}
                          onClick={() => setIsDropdownActive(!isDropdownActive)}
                        >
                          <div className="bg-transparent">
                            <span
                              className="iconify"
                              data-icon={`flag:${selectedCountry.code.toLowerCase()}-4x3`}
                            ></span>
                            <strong>+{selectedCountry.phone}</strong>
                          </div>
                        </div>
                        {isDropdownActive && (
                          <div className="options" ref={selectBoxRef}>
                            <input
                              type="text"
                              className="search-box text-black placeholder:text-black"
                              value={searchQuery}
                              onChange={handleSearchChange}
                              placeholder="Search for a country..."
                            />
                            <ol>
                              {filteredCountries.map((country) => (
                                <li
                                  key={country.code}
                                  className="option"
                                  onClick={() => handleOptionClick(country)}
                                >
                                  <div>
                                    <span
                                      className="iconify"
                                      data-icon={`flag:${country.code.toLowerCase()}-4x3`}
                                    ></span>
                                    <span className="country-name text-black">
                                      {country.name}
                                    </span>
                                  </div>
                                  <strong>+{country.phone}</strong>
                                </li>
                              ))}
                            </ol>
                          </div>
                        )}
                        <input
                          {...field}
                          type="tel"
                          // placeholder="Phone number"
                          ref={inputBoxRef}
                          className="phone-input "
                          placeholder={`${selectedCountry.phone} 123 456 789`}
                        />
                      </div>
                    </div>
                    <FormMessage />
                  </div>
                )}
              />
              <div className="mt-4" />
              <FormField
                control={form.control}
                name="email"
                render={({ field, fieldState: { error } }) => (
                  <FormItem className="relative">
                    <FormLabel>Email * </FormLabel>
                    <FormControl>
                      <div className="w-full relative outline-none  h-10 text-sm mt-2">
                        <input
                          type="email"
                          {...field}
                          className="w-full outline-none  h-10  text-sm"
                          placeholder="name@example.com"
                        />
                      </div>
                    </FormControl>
                    {error?.message && (
                      <FormMessage className="mt-2 z-10 relative">
                        {error.message}
                      </FormMessage>
                    )}
                  </FormItem>
                )}
              />{" "}
              <div className="mt-10" />
              <div className="flex items-center space-x-2 mr-auto">
                <button className="text-blueish w-20  py-2" type="submit">
                  Ok
                </button>
                <p className="text-white text-xs font-thin">
                  press <span className="font-semibold">Enter ↵</span>
                </p>
              </div>
            </div>
          </form>
        </Form>
      </div>
    </div>
  );
};

export default FormTypeIntroduction;
