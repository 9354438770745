import { useAtom } from "jotai";
import { FaChevronUp } from "react-icons/fa6";
import { pathMap } from "src/atom/atoms";
import { useGoingUp } from "src/hooks/useScrollUpAndDown";

import { cn } from "src/lib/utils";

const ScrollupAndDownButton = () => {
  const setIsGoingUp = useGoingUp((s) => s.setGoingUp);

  const [path, setPath] = useAtom(pathMap);

  const handleStepBackward = () => {
    setIsGoingUp(true);
    setPath(path.slice(0, -1));
  };

  return (
    <div className="fixed lg:bottom-32 lg:ight-32 bottom-10 right-10">
      <div className="flex items-center ">
        <div
          onClick={() => handleStepBackward()}
          className={cn(
            "bg-[#f8f8f8] shadow cursor-pointer active:bg-gray-200 border h-8 w-10 rounded-full ml-auto flex flex-col items-center justify-center ",
            path.length === 0 && "text-gray-400 active:bg-[#f8f8f8]"
          )}
        >
          <FaChevronUp className="h-5 w-5" />
        </div>
      </div>
    </div>
  );
};

export default ScrollupAndDownButton;
