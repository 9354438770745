import { useEffect, useRef, useState } from "react";
import { useGoingUp } from "src/hooks/useScrollUpAndDown";
import { FormProps } from "src/types";
import { Form } from "../ui/Form";
import { z } from "zod";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FiArrowRight } from "react-icons/fi";
import { cn } from "src/lib/utils";
import { BsChevronDown } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";
import OptionsToSelect from "../cards/OptionsToSelect";

const schemaForm = z.object({
  option: z.object({
    id: z.string().min(1),
    option: z.string().min(1),
  }),
});
type FormSchema = z.infer<typeof schemaForm>;

const FormTypeSelect = ({
  path,
  setPath,
  description,
  formId,
  options,
  questions,
  title,
}: FormProps) => {
  const goingUp = useGoingUp((s) => s.goingUp);
  const [animationFlicker, setAnimationFlicker] = useState(false);
  const [optionSelected, setOptionSelected] = useState({ id: "", option: "" });
  const [transition, setTransition] = useState(false);
  const [optionsToSelect, setOptionsToSelect] = useState<
    { value: string; label: string }[]
  >([]);
  const [showModalOptions, setShowModalOptions] = useState(false);
  const modalRef = useRef<HTMLUListElement>(null);
  const setIsGoingUp = useGoingUp((s) => s.setGoingUp);

  useEffect(() => {
    setTransition(false);
    form.reset({
      option: {
        id: "",
        option: "",
      },
    });
    setOptionSelected({ id: "", option: "" });
  }, [formId]);

  const form = useForm<FormSchema>({
    resolver: zodResolver(schemaForm),
    defaultValues: {
      option: {
        id: "",
        option: "",
      },
    },
  });

  useEffect(() => {
    const optionsArray = options?.map(
      (option: { id: string; option_text: string }) => {
        return {
          value: option.id,
          label: option.option_text,
        };
      }
    );

    setOptionsToSelect(optionsArray);
  }, [options]);

  const handleChangePath = (option: { id: string }) => {
    const objIndex = path.findIndex(
      (obj: { question_id: string; id: string }) => obj.question_id === formId
    );

    if (objIndex === -1) {
      setPath((prev: any) => [
        ...prev,
        {
          question_id: formId,
          id: option?.id,
        },
      ]);
    } else {
      path[objIndex].id = option.id;
    }
  };
  const handleStepForward = (option?: any) => {
    handleChangePath(option);
    setIsGoingUp(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 300);
  }, [formId]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowModalOptions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div
      className={`transition-all ease-in-out duration-500 ${
        transition
          ? "transform translate-y-0"
          : goingUp
          ? "-translate-y-full opacity-20"
          : "translate-y-full opacity-20"
      } w-full h-full items-center justify-center`}
    >
      <div className="flex flex-col justify-center h-full w-full mb-10 relative">
        <Form {...form}>
          <h1 className="font-sans text-3xl lg:text-4xl font-medium flex items-center space-x-2">
            <h4 className="flex items-start lg:items-center space-x-2">
              <span className="text-sm lg:text-lg ">
                {formId?.replace("q", "")}
              </span>
              <FiArrowRight className="h-5 w-5" />
            </h4>
            <span>{title}*</span>
          </h1>
          {description !== "" && (
            <h3 className="text-lg text-gray-400 font-medium mt-4">
              {description}
            </h3>
          )}
          <div className="mt-10" />
          <Controller
            name="option"
            control={form.control}
            render={({ field }) => (
              <>
                <div className="relative">
                  <input
                    onClick={() => setShowModalOptions(true)}
                    type="text"
                    value={optionSelected.option}
                    placeholder="Type or select an option"
                    className=" bg-transparent w-full hover:bg-transparent  py-2 outline-none text-2xl flex-grow font-normal hover:cursor-text border-b focus:border-b "
                  />
                  {optionSelected.id !== "" ? (
                    <IoMdClose
                      onClick={() => setOptionSelected({ id: "", option: "" })}
                      className="h-6 w-6 text-[#FBCE37]  absolute top-4 right-0 "
                    />
                  ) : (
                    <BsChevronDown
                      className={cn(
                        "h-6 w-6 text-[#FBCE37] absolute top-4 right-0  transition-all duration-200 ease-in-out",
                        showModalOptions && "rotate-180"
                      )}
                    />
                  )}
                </div>

                <ul
                  ref={modalRef}
                  className={cn(
                    "h-60 overflow-y-auto transition-all hidden ease-out duration-300",
                    showModalOptions && "block"
                  )}
                >
                  {optionsToSelect.map((val) => (
                    <OptionsToSelect
                      key={val.value}
                      val={val}
                      animationFlicker={animationFlicker}
                      field={field}
                      handleStepForward={handleStepForward}
                      optionSelected={optionSelected}
                      setAnimationFlicker={setAnimationFlicker}
                      setOptionSelected={setOptionSelected}
                      setShowModalOptions={setShowModalOptions}
                    />
                  ))}
                </ul>
              </>
            )}
          />
          <button
            className=" text-blueish w-20 mr-auto py-2 mt-4"
            type="submit"
          >
            <p className="font-medium">Ok</p>
          </button>
        </Form>
      </div>
    </div>
  );
};

export default FormTypeSelect;
