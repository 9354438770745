import "./App.css";
import React from "react";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ScrollupAndDownButton from "./components/scrollUpAnDown/ScrollupAndDown";
import FormTestLogic from "./components/FormtTestLogic";

const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Form />
    </QueryClientProvider>
  );
}

function Form() {
  return (
    <div className=" h-screen w-screen ">
      <div className="bg-[url('../public/img/large.jpg')] h-full w-full">
        <div className="h-full w-full bg-black/85 flex flex-col items-center justify-center">
          <img
            src={require("./assets/piranha.png")}
            alt="piranha logo"
            className="absolute top-10 left-10 w-16 h-12 lg:top-24 lg:left-24  object-cover"
          />
          <FormTestLogic />
          <ScrollupAndDownButton />
        </div>
      </div>
    </div>
  );
}
