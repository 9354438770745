import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/Form";
import { useAddresDetails, useUserInfo } from "src/hooks/useForms";
import { FiArrowRight } from "react-icons/fi";
import { useEffect, useState } from "react";
import { MdErrorOutline, MdOutlineDone } from "react-icons/md";
import { useQuery } from "@tanstack/react-query";
import Select from "react-select";
import { Label } from "../ui/Label";

import FormTypeSubmitMessage from "./FormTypeSubmitMessage";
import { customStyles } from "src/styles/CustomStylesSelect";
import { useGoingUp } from "src/hooks/useScrollUpAndDown";
import { EndingTypes } from "src/types";

const formSchema = z.object({
  address: z.string().min(1, "Address field is required"),
  address2: z.string().min(1, "Address line 2 is required").optional(),
  city: z.string().min(1, "City field is required"),
  state: z.string().min(1, "State field is required"),
  zipCode: z.string().min(1, "Zipcode field is required"),
  country: z.string().min(1, "Country field is required"),
});
type FormSchema = z.infer<typeof formSchema>;
interface FormProps {
  ending: EndingTypes;

  path: any;
}

const FormAddressDetails = ({ ending, path }: FormProps) => {
  const setAddressDetails = useAddresDetails((s) => s.setAddressDetails);
  const userInfo = useUserInfo((s) => s.userInfo);
  const [submitFormResponse, setSubmitFormResponse] = useState<any>();
  const [errorSubmitResponse, setErrorSubmitResponse] = useState<any>("");
  const addressDetails = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      address: "",
      address2: "",
      city: "",
      state: "",
      zipCode: "",
      country: "",
    },
  });
  const [transition, setTransition] = useState(false);
  const goingUp = useGoingUp((s) => s.goingUp);
  const [countriesArray, setCountriesArray] = useState([]);
  const [showEnding, setShowEnding] = useState(false);

  useEffect(() => {
    if (errorSubmitResponse) {
      setTimeout(() => {
        setErrorSubmitResponse("");
      }, 5000);
    }
  }, [errorSubmitResponse]);

  const formData = new FormData();
  formData.append("_method", "POST");
  formData.append("email", userInfo?.email);
  formData.append("first_name", userInfo?.firstName);
  formData.append("last_name", userInfo?.lastName);
  formData.append("phone_number", userInfo?.phoneNumber);

  const onSubmit = async (values: FormSchema) => {
    setAddressDetails(values);
    setShowEnding(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 100);
  }, []);
  const getCountries = async () => {
    const response = await fetch(
      `https://restcountries.com/v3.1/all?fields=name,flags`
    )
      .then((res) => res.json())

      .catch((err) => {
        console.log(err);
      });
    return response;
  };
  const { data: countries } = useQuery({
    queryKey: ["countries"],
    queryFn: getCountries,
  });
  useEffect(() => {
    const countriesData = countries?.map(
      (option: { flags: { png: string }; name: { common: string } }) => {
        return {
          value: option.flags.png.slice(-6).split(".")[0],
          label: (
            <div className="flex items-center space-x-4">
              <img
                alt={`${option.name.common}`}
                src={option.flags.png}
                className="h-7 w-10 object-cover"
              />
              <p className="text-[16px]">{option.name.common}</p>
            </div>
          ),
        };
      }
    );
    setCountriesArray(countriesData);
  }, [countries]);

  if (showEnding) {
    return <FormTypeSubmitMessage ending={ending} path={path} />;
  }

  return (
    <div
      className={`transition-all ease-in-out duration-500 pt-10 ${
        transition
          ? "transform translate-y-0 opacity-100"
          : goingUp
          ? "-translate-y-full opacity-20 "
          : "translate-y-full opacity-20"
      }  w-full h-full items-center justify-center`}
    >
      <div className="flex flex-col items-center justify-center lg:h-full relative  w-full mb-10">
        <Form {...addressDetails}>
          <form
            className="flex flex-col w-full"
            onSubmit={addressDetails.handleSubmit(onSubmit)}
          >
            <h1 className="font-sans  text-3xl lg:text-4xl font-medium flex items-center space-x-2 mb-10">
              <h4 className="flex items-center space-x-2">
                <FiArrowRight className="h-5 w-5" />
              </h4>
              <span>Address Details*</span>
            </h1>

            <div className="flex flex-col mt-10 w-full">
              <FormField
                control={addressDetails.control}
                name="address"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Address*</FormLabel>
                    <FormControl>
                      <div className="w-full relative outline-none   h-10 text-sm mt-2">
                        <input
                          type="text"
                          {...field}
                          className="w-full outline-none h-10 text-sm"
                          placeholder="65 Hansen Way"
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="mt-10" />
              <FormField
                control={addressDetails.control}
                name="address2"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Address line 2</FormLabel>
                    <FormControl>
                      <div className="w-full relative outline-none   h-10 text-sm mt-2">
                        <input
                          type="text"
                          {...field}
                          className="w-full outline-none   h-10  text-sm"
                          placeholder="Apartment 4"
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="mt-10" />
              <FormField
                control={addressDetails.control}
                name="city"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>City/Town*</FormLabel>
                    <FormControl>
                      <div className="w-full relative outline-none   h-10 text-sm mt-2">
                        <input
                          type="text"
                          {...field}
                          className="w-full outline-none   h-10  text-sm"
                          placeholder="Palo Alto"
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="mt-10" />
              <FormField
                control={addressDetails.control}
                name="state"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>State/Region/Province*</FormLabel>
                    <FormControl>
                      <div className="w-full relative outline-none   h-10 text-sm mt-2">
                        <input
                          type="text"
                          {...field}
                          className="w-full outline-none   h-10  text-sm"
                          placeholder="California"
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="mt-10" />
              <FormField
                control={addressDetails.control}
                name="zipCode"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Zip/Post code*</FormLabel>
                    <FormControl>
                      <div className="w-full relative outline-none h-10 text-sm mt-2">
                        <input
                          type="text"
                          {...field}
                          className="w-full outline-none h-10  text-sm"
                          placeholder="94304"
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="mt-10" />
              <Controller
                name="country"
                render={({ field }) => (
                  <>
                    <Label className="mb-3">Country</Label>
                    <Select
                      styles={customStyles}
                      options={countriesArray}
                      onChange={(val: any) => {
                        field.onChange(val.value);
                      }}
                    />
                  </>
                )}
              />

              <div className="mt-10" />
              <button
                className="  text-blueish w-24 mr-auto py-2"
                type="submit"
              >
                <p className="font-medium">Submit</p>
              </button>
            </div>
          </form>
        </Form>
        {submitFormResponse?.mailSendresult === " Mail sent " && (
          <div className="flex items-center space-x-2">
            <MdOutlineDone className="h-5 w-5 text-green-500" />
            <h1 className="font-medium text-green-500">Mail sent</h1>
          </div>
        )}
        {errorSubmitResponse !== "" && (
          <div className="flex items-center space-x-2">
            <MdErrorOutline className="h-5 w-5 text-gray-400" />
            <h1 className="font-medium text-gray-400">{errorSubmitResponse}</h1>
          </div>
        )}
      </div>
    </div>
  );
};

export default FormAddressDetails;
