export const customStyles = {
    container: (provided: any, _state: any) => ({
        ...provided,
        // width: 300,
        margin: "0 auto",
        width: "100%",
    }),
    control: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: "#FBCE371A",
        borderColor: state.isFocused ? "transparent" : "transparent",
        "&:hover": {
            borderColor: "#FBCE37",
        },
        boxShadow: state.isFocused ? "2px #FBCE37" : "none",
    }),
    input: (provided: any, _state: any) => ({
        ...provided,
        color: "#FBCE37",
        fontSize: "23px",
    }),
    menu: (provided: any, _state: any) => ({
        ...provided,
        borderRadius: "0 0 4px 4px",
        marginTop: 0,
        color: "#FBCE37",
        backgroundColor: "black",
        zIndex: 10,
        fontSize: "23px",
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isSelected ? "rgba(251, 206, 55, 0.7)" : "#FBCE371A",
        "&:hover": {
            backgroundColor: "rgba(251, 206, 55, 0.7)",
        },
    }),
    placeholder: (provided: any, _state: any) => ({
        ...provided,
        color: "#FBCE37",
        fontSize: "23px",
    }),
    singleValue: (provided: any, _state: any) => ({
        ...provided,
        color: "#FBCE37",
        fontSize: "23px",
    }),
};