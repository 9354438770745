import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FiArrowRight } from "react-icons/fi";
import { cn } from "src/lib/utils";
import { FormProps } from "src/types";
import { Form } from "../ui/Form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { MdCheck } from "react-icons/md";
import { useGoingUp } from "src/hooks/useScrollUpAndDown";
import ListCardOption from "./ListCardOption";

const schemaForm = z.object({
  options: z.array(
    z.object({
      id: z.any(),
      option: z.any(),
      img_path: z.any().nullable(),
    })
  ),
});
type FormSchema = z.infer<typeof schemaForm>;
const FormTypeListMultiple: React.FC<FormProps> = ({
  description,
  formId,
  options,
  path,
  setPath,
  title,
}) => {
  const [transition, setTransition] = useState(false);
  const setIsGoingUp = useGoingUp((s) => s.setGoingUp);
  const [selectedOptions, setSelectedOptions] = useState([]);
  useEffect(() => {
    setTransition(false);
  }, [formId]);
  const form = useForm<FormSchema>({
    resolver: zodResolver(schemaForm),
    defaultValues: {
      options: [],
    },
  });
  const [_optionSelected, setOptionSelected] = useState<{
    id: string;
    option_text: string;
    img_path: string | null;
  }>({
    id: "",
    option_text: "",
    img_path: "",
  });
  const goingUp = useGoingUp((s) => s.goingUp);

  const handleChangePath = () => {
    const objIndex = path.findIndex(
      (obj: { question_id: string; id: string }) => obj.question_id === formId
    );

    if (objIndex === -1) {
      setPath((prev: any) => [
        ...prev,
        {
          question_id: formId,
          id: selectedOptions,
        },
      ]);
    } else {
      path[objIndex].id = selectedOptions;
    }
  };

  const toggleOption = (optionId: string) => {
    setSelectedOptions((prevSelected: any) => {
      const isSelected = prevSelected.includes(optionId);
      const updatedSelectedOptions = isSelected
        ? prevSelected.filter((id: any) => id !== optionId)
        : [...prevSelected, optionId];

      form.setValue("options", updatedSelectedOptions);

      return updatedSelectedOptions;
    });
  };

  const handleStepForward = (option?: any) => {
    handleChangePath();
    setIsGoingUp(false);
  };
  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 300);
  }, [formId]);

  const onSubmit = async (values: FormSchema) => {
    console.log(values);
  };
  return (
    <div
      className={`transition-all ease-in-out duration-500 ${
        transition
          ? "transform translate-y-0"
          : goingUp
          ? "-translate-y-full opacity-20"
          : "translate-y-full opacity-20"
      } w-full h-full items-center justify-center`}
    >
      <div className="flex flex-col justify-center  relative  lg:h-full w-full mb-10">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <h1 className="font-sans text-3xl lg:text-4xl font-medium flex items-center space-x-2">
              <h4 className="flex items-center space-x-2">
                <span className="text-lg ">{formId?.replace("q", "")}</span>
                <FiArrowRight className="h-5 w-5" />
              </h4>
              <span>
                {title === "Video question"
                  ? "Do you require in-store fitting"
                  : title}
                *
              </span>
            </h1>
            {description !== "" ? (
              <h3 className="text-lg text-gray-400 font-medium mt-4">
                {description}
              </h3>
            ) : (
              <div className="mt-4"></div>
            )}
            <p>Choose as many as you like</p>
            <Controller
              control={form.control}
              name="options"
              render={({ field }) => (
                <ul>
                  {options?.map((option, i: number) => (
                    <ListCardOption
                      key={option.id}
                      options={options}
                      selectedOptions={field.value}
                      toggleOption={toggleOption}
                      option={option}
                    />
                  ))}
                </ul>
              )}
            />
            <button
              onClick={() => handleStepForward()}
              className=" text-blueish w-20 ml-auto py-2"
              type="button"
            >
              <p className="font-medium">Ok</p>
            </button>
          </form>
        </Form>
      </div>
    </div>
  );
};

export default FormTypeListMultiple;
