import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";


type UserInfo = {
  firstName: string; email: string; lastName: string; phoneNumber: string;
}
// USER INFO
interface UserInfoState {
  userInfo: UserInfo
}

interface UserInfoActions {
  setUserInfo: (userInfo: UserInfo) => void
}
type UseUserInfo = UserInfoState & UserInfoActions;


const useUserInfo = create<UseUserInfo>(
  (set) => ({
    userInfo: { email: "", firstName: "", lastName: "", phoneNumber: "" },
    setUserInfo: (userInfo) => set({ userInfo: userInfo }),
  })
);

export { useUserInfo };

//CLOSEST BRANCH STATE

interface ClosestBranchState {
  closestBranch: string | null
}

interface ClosestBranchActions {
  setClosestBranch: (closestBranch: string | null) => void
}
type UseClosestBranch = ClosestBranchState & ClosestBranchActions;


const useClosestBranch = create<UseClosestBranch>((set) => ({
  closestBranch: null,
  setClosestBranch: (closestBranch) => set({ closestBranch: closestBranch }),
}));
export { useClosestBranch };

//VEHICLE STATE


interface VehicleState {
  vehicle: string | null
}

interface VehicleActions {
  setVehicle: (closestBranch: string | null) => void
}

type UseVehicle = VehicleState & VehicleActions

const useVehicle = create<UseVehicle>((set) => ({
  vehicle: null,
  setVehicle: (vehicle) => set({ vehicle: vehicle }),
}));

export { useVehicle };

// YEAR STATE

interface YearState {
  year: string | null
}

interface YearActions {
  setYear: (closestBranch: string | null) => void
}

type UseYear = YearState & YearActions


const useYear = create<UseYear>((set) => ({
  year: null,
  setYear: (year) => set({ year: year }),
}));

export { useYear };

// ADDRESS STATE

type AddressType = {
  address: string;
  state: string;
  city: string;
  zipCode: string;
  address2?: string
  country: string
}


interface AddressState {
  addressDetails: AddressType
}

interface AddressActions {
  setAddressDetails: (addressDetails: AddressType) => void;
}

type UseAddressDetails = AddressState & AddressActions

const useAddresDetails = create<UseAddressDetails>()(
  persist(
    (set) => ({
      addressDetails: { address: "", city: "", country: "", state: "", zipCode: "", address2: "" },
      setAddressDetails: (addressDetails) =>
        set({ addressDetails: addressDetails }),
    }),
    {
      name: "address_details",
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export { useAddresDetails };



